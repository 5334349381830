
const state = {
  orderInfo: {},
  orderAffirmObj: {},
  orderAffirmInfo: {},
  payInfo: {}
}

const mutations = {
  ORDER_INFO: (state, data) => {
    state.orderInfo = data
  },
  ORDER_AFFIRM: (state, { key, value}) => {
    state.orderAffirmObj[key] = value
  },
  ORDER_AFFIRM_INFO: (state, data) => {
    state.orderAffirmInfo = data
  },
  PAY_INFO: (state, data) => {
    state.payInfo = data
  }
}

const actions = {
  orderInfo({ commit }, data) {
    commit('ORDER_INFO', data)
  },
  orderAffirm({ commit }, { key, value}) {
    commit('ORDER_AFFIRM', { key, value})
  },
  orderAffirmInfo({ commit }, data) {
    commit('ORDER_AFFIRM_INFO', data)
  },
  payInfo({ commit }, data) {
    commit('PAY_INFO', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

