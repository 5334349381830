/*
 * @Author: your name
 * @Date: 2020-06-09 18:40:17
 * @LastEditTime: 2020-06-17 11:10:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\modules\db.js
 */
import { getMy } from "@/service/api.js";
export default {
  namespaced: true,
  state: {
    userdata: null,
    uid: 0,
    token: "",
    car: 0,
  },
  getters: {
    getuserdata(state) {
      return state.userdata;
    },
    getUid(state) {
      return state.uid;
    },
    getToken(state) {
      return state.token;
    },
    getcar(state) {
      return state.car;
    },
  },
  mutations: {
    //设置 用户信息
    SETUSERDATA(state, data) {
      state.userdata = { ...state.userdata, ...data };
      // console.log(data);
      localStorage.setItem("USERDATA", JSON.stringify(data));
    },
    // 读取 用户信息
    LOADUSERDATA(state) {
      let value = localStorage.getItem("USERDATA");
      if (value) {
        state.userdata = JSON.parse(value);
      }
    },
    //设置 token
    SETTOKEN(state, token) {
      state.token = token;
      localStorage.setItem("TOKEN", JSON.stringify(token));
    },
    //读取 token
    LOADTOKEN(state) {
      let data = localStorage.getItem("TOKEN");
      if (data) {
        state.token = JSON.parse(data);
      }
    },
    //设置 uid
    SETUID(state, data) {
      state.uid = data;
      localStorage.setItem("UID", JSON.stringify(data));
    },
    //读取 uid
    LOADUID(state) {
      let data = localStorage.getItem("UID");
      if (data) {
        state.uid = JSON.parse(data);
      }
    },

    //设置 购物车数量
    SETCAR(state, data) {
      state.car = data;
      localStorage.setItem("CAR", JSON.stringify(data));
    },
    //读取 购物车数量
    LOADCAR(state) {
      let data = localStorage.getItem("CAR");
      if (data && data!='undefined') {
        state.car = JSON.parse(data);
      }
    },

    // 清空用户信息
    CLEAR(state) {
      localStorage.setItem("msg",'1')
      // return
      state.userdata = null;
      state.token = "";
      state.uid = 0;
      state.car = 0;
      localStorage.clear();
    },
  },
  actions: {
    logout({ commit }) {
      //退出登录
      // console.log("1111");
      commit("CLEAR");
    },
    async getInfo({ dispatch, rootState }) {
      await getMy({ uid: rootState.uid }).then((res) => {
        if (res.status == 401) {
          dispatch("logout");
        }
      });
    },
  },
};
