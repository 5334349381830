import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: "/home.html",
  },
  {
    path: "/home.html",
    name: "Home",
    component: () => import("../views/home/Home.vue"),
    meta: { title: "首页 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/createLogin.html",
    name: "login",
    component: () => import("../views/login/login.vue"),
    meta: { title: "登录 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/watchlogin",
    name: "watchlogin",
    component: () => import("../views/login/watchlogin.vue"),
    meta: { title: "伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/login/register.vue"),
    meta: { title: "注册 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/introduce.html",
    name: "CompanyIntroduction",
    component: () => import("../views/companyIntroduction/index.vue"),
    meta: { title: "公司简介 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/problem.html",
    name: "Issue",
    component: () => import("../views/issue/index.vue"),
    meta: { title: "常见问题 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/serieslist",
    name: "serieslist",
    component: () => import("../views/series/serieslist.vue"),
    meta: { title: "系列 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/shopping",
    name: "Shopping",
    component: () => import("../views/shopping/index.vue"),
    meta: { title: "购物车 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/confirmOrder",
    name: "ConfirmOrder",
    component: () => import("../views/confirmOrder/index.vue"),
    meta: { title: "确认下单 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/irisgeminfo.html",
    name: "Irisgem",
    component: () => import("../views/irisgem/Irisgem.vue"),
    meta: {title: " 伊莉丝钻石品牌官网|新生儿纪念钻石定制"},
  },
  {
    path: "/productInfo.html",
    name: "particulars",
    component: () => import("../views/production/particulars.vue"),
    meta: { title: "伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/authority.html",
    name: "Authority",
    component: () => import("../views/authority/Authority.vue"),
    meta: { title: "权威认证 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/production.html",
    name: "Production",
    component: () => import("../views/production/Production.vue"),
    meta: { 
      title: "臻品展示 伊莉丝钻石品牌官网|新生儿纪念钻石定制" ,
      keepAlive:true
    },
  },
  {
    path:'/productList.html',
    name:'seriesas',
    component:() => import('../views/series/series_as.vue'),
    meta: { title: "系列 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/diamondorder",
    name: "DiamondOrder",
    component: () => import("../views/production/DiamondOrder.vue"),
    meta: { title: "裸钻定制 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/designer.html",
    name: "StarDesigner",
    component: () => import("../views/production/StarDesigner.vue"),
    meta: { title: "设计师 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path:'/datalists',
    name:'Datalist',
    component:() => import('../views/series/datalist.vue'),
    meta:{title:'伊莉丝钻石品牌官网|新生儿纪念钻石定制'}
  },
  {
    path: "/family.html",
    name: "CustomerStory",
    component: () => import("../views/customerStory/CustomerStory.vue"),
    meta: { title: "客户故事 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/servicecenter",
    name: "ServiceCenter",
    component: () => import("../views/serviceCenter/ServiceCenter.vue"),
    meta: { title: "伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/postdetails.html",
    name: "NewsInfo",
    component: () => import("../views/serviceCenter/NewsInfo.vue"),
    meta: { title: "帖子详情 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/newslist.html",
    name: "NewsList",
    component: () => import("../views/serviceCenter/NewsList.vue"),
    meta: { title: "最新资讯 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: '/orderargument',
    name: 'orderargument',
    component: () => import('../views/file/OrderArgument.vue'),
    meta: { tiele: '伊莉丝钻石品牌官网|新生儿纪念钻石定制' }
  },
  {
    path: '/privacypolicy',
    name: 'privacupolicy',
    component: () => import('../views/file/PrivacyPolicy.vue'),
    meta: { tiele: '隐私保护政策 伊莉丝钻石品牌官网|新生儿纪念钻石定制' }
  },
  {
    path:'/theme',
    name:'theme',
    component:() => import('../views/production/theme.vue'),
    meta:{title:'伊莉丝钻石品牌官网|新生儿纪念钻石定制'}
  },
  {
    path: "/series",
    name: "Series",
    component: () => import("../views/production/Series.vue"),
    meta: { title: "伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/paycode",
    name: "PayCode",
    component: () => import("../views/pay/PayCode"),
    meta: { title: "伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/order/finish",
    name: "OrderProcess",
    component: () => import("../views/pay/OrderProcess"),
    meta: { title: "伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/confirmPay",
    name: "confirmPay",
    component: () => import("../views/pay/confirmPay"),
    meta: { title: "伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
  },
  {
    path: "/mycenter",
    name: "MyCenter",
    component: () => import("../views/myCenter/MyCenter.vue"),
    meta: { title: "我的 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
    redirect: "/orderlist",
    children: [
      {
        path: "/orderlist",
        name: "OrderList",
        component: () => import("../views/myCenter/OrderList.vue"),
        meta: { title: "我的订单 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
      },
      {
        path: "/orderdetail",
        name: "OrderDetail",
        component: () => import("../views/myCenter/OrderDetail.vue"),
        meta: { title: "订单详情 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
      },
      {
        path: "/orderevaluate",
        name: "OrderEvaluate",
        component: () => import("../views/myCenter/OrderEvaluate.vue"),
        meta: { title: "伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
      },
      {
        path: "/orderlogistics",
        name: "OrderLogistics",
        component: () => import("../views/myCenter/OrderLogistics.vue"),
        meta: { title: "伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
      },
      {
        path: "/ordershare",
        name: "OrderShare",
        component: () => import("../views/myCenter/OrderShare.vue"),
        meta: { title: "伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
      },
      {
        path: "/account",
        name: "Account",
        component: () => import("../views/myCenter/Account.vue"),
        meta: { title: "账号信息 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
      },
     
      {
        path: "/address",
        name: "Address",
        component: () => import("../views/myCenter/Address.vue"),
        meta: { title: "收货地址 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
      },
      {
        path: "/collect",
        name: "Collect",
        component: () => import("../views/myCenter/Collect.vue"),
        meta: { title: "我的收藏 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
      },
      {
        path: "/giftbag",
        name: "Giftbag",
        component: () => import("../views/myCenter/Giftbag.vue"),
        meta: { title: "尊享礼包 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
      },
      {
        path: "/vipbirthday",
        name: "Vipbirthday",
        component: () => import("../views/myCenter/Vipbirthday.vue"),
        meta: { title: "会员生日 伊莉丝钻石品牌官网|新生儿纪念钻石定制" },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});
router.beforeEach((to, from, next) => {
  if (
    document.getElementById("sapp") &&
    document.getElementById("sapp").scrollTop
  ) {
    document.getElementById("sapp").scrollTop = 0;
  }
  if (to.matched.length === 0) {
    // 输入错误路径直接跳转到这个
    next({ name: "Home" });
  } else {
    document.title = to.meta.title ? to.meta.title + "" : "";
    next();
  }
});

export default router;
