import axios from "axios";
import qs from "qs";
// import Cookies from "js-cookie";
import router from "../router/index";
import store from "@/store";
let pname = "IRS_001_W_1";

import { Message } from "element-ui";
// let test = 1;

// let user = JSON.parse(sessionStorage.getItem('name')).user
// let user = JSON.parse(Cookies.get("user"))
// let uid = user.uid
function obj() {
  let uid = store.getters["user/getUid"];
  return {
    uid: uid,
    pname: "IRS_001_W_1",
  };
}
// let pname = "IRS_001_P_1"
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.acceptCharset = "utf-8";
let config = {
  baseURL: "https://www.irisgem.cn/gemservers",
  timeout: 6 * 1000, // Timeout
};

const service = axios.create(config);
service.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    let token = store.getters["user/getToken"];
    // console.log(token);
    // const token = Cookies.get("token");
    if (token) {
      config.headers.token = token;
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  function(response) {
    if (response.data.status === 401) {
      sessionStorage.setItem("name", "");
      store.dispatch("user/logout");
      router.push({ path: "/createLogin.html" });
    } else if (response.data.status !== 200) {
      if (response.config.params && response.config.params["notMessage"]) {
        console.log("err");
      } else {
        Message({
          type: "warning",
          message: response.data.message || "报错了",
        });
      }
    }
    // 响应数据
    return response.data;
  },
  function(error) {
    // 响应错误
    if (error.response && +error.response.status === 401) {
      console.log(401);
    }
    return Promise.reject(error.response);
  }
);
export const get = (url, params) => {
  params = {
    ...params,
    pname,
    ...obj(),
  };
  return service({
    url,
    method: "get",
    params,
  });
};

export const post = (url, data) => {
  data = {
    ...data,
    pname,
    ...obj(),
  };
  return service({
    url,
    method: "post",
    data: qs.stringify(data),
    // data
  });
};

export const upload = (url, data) => {
  data.append("pname", pname);
  data.append("uid", store.getters["user/getUid"]);
  return service({
    data,
    url,
    method: "post",
  });
};

export const put = (url, data) => {
  data = {
    ...data,
    pname,
  };
  return service({
    url,
    method: "put",
    data,
  });
};

export const del = (url, data) => {
  data = {
    ...data,
    pname,
  };
  return service({
    url,
    method: "delete",
    data,
  });
};
export default service;
