<!--
 * @Author: your name
 * @Date: 2020-06-07 13:14:54
 * @LastEditTime: 2020-07-28 18:06:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\views\serviceCenter\serve_s.vue
--> 
<template>
  <div class="serve_s" @click.stop="()=>{}">
    <div class="left">
      <h3>联系我们</h3>
      <div class="sendMy">
        <p @click="footer()">
          <img src="@/assets/images/center/icon_tel.png" />客服电话
        </p>
        <p @click="footer()">
          <img src="@/assets/images/center/icon_wx.png" />官方微信
        </p>
        <p @click="footer()">
          <img src="@/assets/images/center/icon_email.png" />企业邮箱
        </p>
      </div>
      <div class="sendMy">
        <h3 @click="issue()">常见问题</h3>
        <h3 @click="vac()">隐私政策</h3>
        <h3 @click="order()">条款细则</h3>
      </div>
    </div>
    <div class="center"></div>
    <div class="right">
      <h3>最新资讯</h3>
      <div class="view-banner">
        <img src="https://img.irisgem.com.cn/web/zxzx/zxzx_nav.jpg" alt  @click="newlist()"/>
        <div class="btn" @click="newlist()">探索</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "serve_s",
  data(){
    return{
    }
  },
  methods:{
    loading(){
       const loading = this.$loading({           
          lock: true,                             
          text: '正在加载...',                     
          spinner: 'el-icon-loading',             
          background: 'rgba(0, 0, 0, 0.3)',     
          target: '.sub-main',                   
          body: true,                              
          customClass: 'mask'                    
        })
        setTimeout(function () {                
          loading.close();                        
        },300)
    },
    //跳转客户故事
    customerstory(){
     this.loading()
      this.$router.push({path:'/family.html'})
    },
    //公司介绍
    js(){
     this.loading()
      this.$router.push({path:'/introduce.html'})
    },
    //常见问题
    issue(){
      this.loading()
      this.$router.push({path:'/problem.html'})

    },
    //条款细则
    order(){
      this.loading()
      this.$router.push({path:'/orderargument'})

    },
    // 隐私
    vac(){
      this.loading()
      this.$router.push({path:'/privacypolicy'})

    },
    //探索
    newlist(){
    this.loading()
    this.$router.push({path:'/newslist.html'})
    },
    //跳转页脚
  footer(){
      document.getElementById("sapp").scrollTop = 999999999
  }
  },
  
};
</script>>
<style lang="less" scoped>
.serve_s {
  display: flex;
  text-align: left;
  line-height: 32px;
  width: 100%;
  color: #333333;
  .left {
    width: 50%;
    height: 570px;
    box-sizing: border-box;
    padding-left: 64px;
    padding-top: 32px;
    cursor: pointer;
    h3 {
      font-size: 20px;
    }
    .sendMy {
      margin-top: 32px;
      padding-bottom: 30px;
      border-bottom: 1px solid #cfcfcf;
      &:last-child {
        border-bottom: none;
      }
      h3 {
        font-size: 18px;
        color: #333333;
      }
      p {
        display: flex;
        align-items: center;
        height: 30px;/* no */
        img {
          margin-right: 15px;
        }
      }
    }
  }
  .right {
    height: 75%;
    width: 721px;
    box-sizing: border-box;
    padding: 32px;
    padding-left: 60px;
    position: relative;
    .btn {
      width: 206px;
      height: 64px;
      background: #921d22;
      line-height: 64px;
      text-align: center;
      color: #ffffff;
      font-size: 22px;
      position: absolute;
      bottom: 20%;
      left: 50%;
      font-weight: 300;
      margin-left: -100px;
      cursor: pointer;
    }
    h3 {
      font-size: 20px;
    }
    .view-banner {
      width: 95%;
      margin-top: 28px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .center {
    width: 1px;
    height: 450px;
    background: #cfcfcf;
    margin-top: 40px;
    margin-left: 50px;
  }
}
</style>