<!--
 * @Author: your name
 * @Date: 2020-06-16 15:10:41
 * @LastEditTime: 2020-09-24 14:03:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\views\authority\keepout.vue
--> 
<template>
        <div class="zhengshu">
         <div class="img">
         <swiper :options="mySwiperOption"   :loop="true">
          <swiper-slide class="swiper-slide" v-for="(item, index) in list" :key="index">
        
          
            <div class="back">
            
               <img src="@/assets/logo.png" alt="" class="logo">
                <p class="back_title">CARBON SOURCE CERTIFICATE</p>


                <div class="info">
                  <img :src="item.pic.picUrl" alt="">
                    <div class="info_in">
                        <p>Color:{{item.color}}</p>
                        <p>Carat:{{item.carat}}</p>
                        <p>Cut:{{item.shape}}</p>
                        <p>Clarity:{{item.clarity}}</p>
                  </div>
                 </div>

              <div class="ison">IRISGEM DIAMOND guarantees the uniqueness of this diamond and it contains the carbon element extracted from the lanugo samples of the attributor.
              </div>

              <div class="inputs">
                  <div class="int_left">
                  <p>Attributor</p>
                  <div>{{item.signature}}</div>
                  </div>
                  <div class="int_right">
                  <p>Certified by</p>
                  <div>
                  <img src="../../assets/images/about/qianming.png" alt="">
                  </div>
                  </div>
              </div>


              <div class="texts">
                <div class="texts_left">
                  <p>Certificate No.</p>
                  <p>{{item.code}}</p>
                </div>        
                <div class="texts_right">
                 <p>Issuing date：</p>
                 <p>{{item.ct}}</p>
                </div>          
            </div>
            
            </div>

          </swiper-slide>
         </swiper>



         </div>
         
         <img src="@/assets/images/shoppingCar/x.png" class="is" @click="ex"/>
        </div>

</template>
<script>
import {mapGetters} from "vuex"
export default {
    name:'keepout',
    data(){
        return{
            list:[],
            mySwiperOption: {
              //自动轮播
              autoplay: {
                delay: 2000,
                //当用户滑动图片后继续自动轮播
                disableOnInteraction: true
              },
              //开启循环模式
              loop: true,
              },
            }
    },
    computed:{
        ...mapGetters({
            aimg: "getimg",
        })
    },
    methods:{
        ex(){
            this.$store.commit('show',false)
        }
    },
    mounted(){
      this.list = this.$store.state.aimg
    }
}
</script>

<style lang="less">
.zhengshu{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  position: relative;
  .img{
    width: 536px;
    height: 742px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-image: url('../../assets/images/about/background.png');
    background-size:100% 100% ;
    position: relative;
    .back{
        width: 100%;
        height: 100%;
        background: url('../../assets/images/about/backim.png');
        background-size:100% 100% ;
        text-align: center;
        .logo{
          width:230px;
          margin: 50px 0;
          margin-bottom: 20px;
          text-align: center;
        }
    }
  }
  .is{
      width: 50px;
      height: 50px;
      position: absolute;
      top: 100px;
      right: 100px;
      &:hover{
          cursor: pointer;
      }
  }
}
.back_title{
    text-align: center;
    font-size:22px;
    font-family:PingFang SC;
    font-weight:500;
    color:rgba(0,0,0,1);
    line-height:48px;
}
.info {
  width: 404px;
  height: 100px;
  margin: 40px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 88px;
    height: 100%;
    object-fit: cover;
  }
  .info_in {
    width: 290px;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; /*溢出则换行*/
    p:nth-child(1),
    p:nth-child(3) {
      width: 40%;
      text-align: left;
      height: 50%;
      line-height: 50px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
    }
    p:nth-child(2),
    p:nth-child(4) {
      width: 55%;
      height: 50%;
      line-height: 50px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
    }
  }
}
.ison{
width: 395px;
font-size:16px;
font-weight:500;
color:rgba(0,0,0,1);
line-height:24px;
margin: 30px auto;
white-space: pre-wrap;
word-wrap: break-word;
table-layout: fixed;
word-break: break-all;        
}
.inputs{
  width: 395px;
  height: 100px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  .int_left{
    width: 50%;
    height: 100%;
    p{
      font-size:18px;
      font-family:PingFang SC;
      font-weight:400;
      color:rgba(0,0,0,1);
      line-height:38px;
    }
    div{
      width:80%;
      height:42px;
      border:1px solid rgba(7,7,7,1);
      text-align: center;
      line-height: 42px;
    }
  }
  .int_right{
    width: 45%;
    height: 100%;
    p{
      font-size:18px;
      font-family:PingFang SC;
      font-weight:400;
      color:rgba(0,0,0,1);
      line-height:38px;
    }
      div{
      width:80%;
      height:52px;
      border:1px dashed rgb(121, 107, 107);
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.texts{
   width: 395px;
  height: 100px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  p{
    font-size:18px;
    font-family:PingFang SC;
    font-weight:400;
    color:rgba(0,0,0,1);
    line-height:30px;
  }
  .texts_left{
    width: 50%;
    height: 100%;
  }
  .texts_right{
    width: 45%;
    height: 100%;
  }
}
</style>