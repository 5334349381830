<template>
  <div id="sapp" @scroll="handleScroll">
    <!-- <div :istop="istop" :class="!istop ? 'hds':'s'">
      <myhead></myhead>
    </div>-->
    <main-header />
    <div class="header-logo">
      <img src="@/assets/logo1.png" alt />
    </div>
    <!-- <router-view /> -->
    <div :class="border?'border tab-list':'tab-list'" style="height:70px;z-index:99">
      <img
        src="@/assets/logo1.png"
        style="width:210px;margin-left:40px;position: absolute;"
        alt
        v-if="istop"
        class="imes"
        @click="s()"
      />

      <div class="tab-content">
        <div
          :class="{ tab: true, actived: actived === item.id }"
          v-for="(item, index) in tabList"
          :key="item.id"
          @click="tabClick(item.id)"
          @mousemove="sweep(index)"
          @mouseleave="lineout"
          class="nav"
          style="line-height:40px;width:100%;font-size:16px;"
        >
          {{ item.label }}
          <template v-if="index == 5">
            <div class="float">
              <serves></serves>
            </div>
          </template>
        </div>
        <div :class="Productdisplay? 'commit commit-block' : 'commit'">
          <prods :data="getdata" v-if="getdata"></prods>
        </div>
      </div>
      <heads type="2" v-if="istop" />
    </div>



  <div class="main">
  <!-- <transition name="fade" mode="out-in"> -->
      <router-view class="router-view"/>
  <!-- </transition> -->

    </div>
    
    <keep v-if="isshow" class="keep"></keep>
     <el-tooltip placement="top" content="返回顶部">
      <back-to-top 
        transitionName="fade"
        :customStyle="myBackToTopStyle" 
        :visibilityHeight="300" 
        :backPosition="50">
      </back-to-top>
    </el-tooltip>
    <div class="footer">
      <main-footer />
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import "babel-polyfill"
import mainFooter from "@/layout/mainFooter.vue";
import mainHeader from "@/layout/mainHeader.vue";
// import myhead from "@/views/head.vue";
import prods from "@/views/production/prod_s.vue";
import { getNData } from "@/service/api.js";
import serves from "@/views/serviceCenter/serve_s.vue";
import heads from "@/layout/components/header_s.vue";
import keep from '@/views/authority/keepout.vue'
import BackToTop from '@/layout/top.vue'
export default {
  name: "sapp",
  components: {
    mainFooter,
    mainHeader,
    // myhead,
    prods,
    serves,
    heads,
    keep,
    BackToTop
  },
  data() {
    return {
       myBackToTopStyle: {
        'right': '100px',
        'bottom': '150px',
        'width': '40px',
        'height': '40px',
        'border-radius': '20px',
        'line-height': '40px', 
        'background': '#fff'
      },
      actived: "home",
      tabList: [
        { id: "/home.html", label: "首页" },
        { id: "/irisgeminfo.html", label: "品牌介绍" },
        { id: "/authority.html", label: "权威认证" },
        { id: "/production.html", label: "臻品展示" },
        { id: "/family.html", label: "客户故事" },
        { id: "#footer", label: "联系我们" },
        { id: "/introduce.html", label: "关于我们" }
      ],
      isshow:false,
      istop: false,
      Productdisplay: false,
      getdata: false,
      servewindow: false,
      border: false,
      getser: false,
      watchs:false,
      // transitionName:'vux-pop-in'
    };
  },
  watch:{
    '$store.state.show':function(to,from){
      // console.log(to)
      this.isshow = to
      if(this.isshow){
         var top = $("#sapp").scrollTop();
        $("#sapp").on("scroll.unable", function() {
          $("#sapp").scrollTop(top);
        });
      }else{
        $("#sapp").unbind("scroll.unable"); 
      }
    },
  },
  created() {
    let ua = window.navigator.userAgent.toLowerCase()
    if (ua.indexOf("micromessenger") != -1) {
      sessionStorage.setItem('watch',true)
    } else {
       sessionStorage.setItem('watch',false)
    }
    // window.addEventListener("beforeunload",()=>{
    //     sessionStorage.setItem("store",JSON.stringify(this.$store.state.name))
    // })
    //  if (sessionStorage.getItem("store") ) {
    //    let name = JSON.parse(sessionStorage.getItem('store'))
    //     // this.$store.replaceState(Object.assign({}, this.$store.state.name,JSON.parse(sessionStorage.getItem("store"))))
    //     this.$store.state.name = name
    // }
  },

  mounted() {
    this.nums();
    getNData().then(res => {
      this.getdata = res.attachment;
    });

    this.watchs = sessionStorage.getItem('watch')
  },
  methods: {
    s(){
      this.$router.push({path:'/home'})
    },
    nums() {
      let nums = JSON.parse(sessionStorage.getItem("nums"));
      if (nums) {
        this.$store.commit("carnum", nums);
      }
    },
    tabClick(id) {
      
      if(id=='#footer'){
        document.getElementById("sapp").scrollTop = 999999999
      }else if(id=='home'){
        document.getElementById("sapp").scrollTop = 0
      }else{
      this.actived = id;
      this.$router.push( id);
      }
    },
    handleScroll(e) {
      if (e.target.scrollTop < 230) {
        this.istop = false;
      }

      if (e.target.scrollTop > 230) {
        this.istop = true;
      }

      if (e.target.scrollTop > 230) {
        this.border = true;
      }
      if (e.target.scrollTop < 230) {
        this.border = false;
      }
      this.$store.commit(
        "height",
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      );
    },
    sweep(key) {
      if (key == 3) {
        this.Productdisplay = true;
      }
    },
    lineout() {
      this.Productdisplay = false;
    }
  }
};
</script>
<style lang="less">
#sapp {
  .imes{
    z-index: 99;
  }
  .keep{
    position: fixed;
    top: 0;
  }
  width: 100vw;
  min-width: 1600px;
  height: 100vh;
  overflow-y: scroll;
  .header-logo {
    width: 400px; /* no */
    margin: 0px auto 0px;
    margin-bottom: -130px;
    position: relative;
    top: -50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.main {
  margin-top: 10px;
}
.tab-list {
  position: sticky;
  top: 0;
  z-index: 15;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tab-content {
    display: flex;
    margin: 0px auto;
    justify-content: space-between;
    width: 50%;
    height: 100%;
    position: relative;
    div:hover {
      cursor: pointer;
    }
  }
  .float {
    width: 1144px;
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    -webkit-animation: keep 0.5s;
    animation: keep 0.5s;
  }
  .nav {
    &:hover {
      .float {
        display: block;
      }
      .float /deep/ .left {
        animation: viewLeft 500ms ease;
      }
      .float /deep/ .right {
        animation: viewRight 500ms ease;
        .btn {
          animation: topToBottom 500ms ease;
        }
      }
    }
  }
  .tab {
    box-sizing: border-box;
    padding-top: 15px;
    padding-bottom: 20px;
    text-align: center;
    font-size: 14px;
    color: #000;
    z-index: 15;
    line-height: 4vw;
  }

  .tab:first-child {
    margin-left: 0;
  }
}
.hds {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;
  margin: 0;
  padding: 0;
}
.s {
  width: 0;
  height: 0;
  overflow: hidden;
}




.commit {
  width: 120%;
  height: 526px;
  position: absolute;
  top: 67px; /* no */
  left: 50%;
  transform: translate(-50%);
  display: none;
  z-index: 99;
  animation: keep 0.5s;
}
.commit:hover {
  display: block;
}
.commit-block {
  display: block;
}
@keyframes keep {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.headcar {
  position: absolute;
  right: 0;
  top: 1vw;
}

.border {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.22), 0 0 6px rgba(0, 0, 0, 0.04);
  width: 100%;
  position: fixed;
  top: 0;
}

</style>
