/*
 * @Author: your name
 * @Date: 2020-06-04 08:54:04
 * @LastEditTime: 2020-08-04 09:53:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\service\api.js
 */

import { get, post } from "../common/query";
export const cancleCol = (params) => get("/pcollect/cancleCol", params);
export const info = (params) => get("/sproduct/info", params);
export const getlist = (params) => get("/sproduct/getlist", params);
export const getNData = (params) => get("/sproduct/getNData", params);
export const login = (params) => get("/login", params);
export const phoneCode = (params) => get("/phoneCode", params);
export const phonelogin = (params) => get("/phonelogin", params);
export const register = (params) => post("/register", params);
export const updatepwd = (params) => post("/updatepwd", params);
export const list = (params) => get("/speech/list", params);
export const getMy = (params) => get("/user/getMyInfo", params);
export const add = (params) => post("/buycar/add", params);
export const myBuyCar = (params) => get("/buycar/myBuyCar", params);
export const series = (params) => get("/series/getlist", params);
export const collect = (params) => post("/pcollect/collect", params);
export const getTheme = (params) => get("/theme/getThemeProlist", params);
export const coInfo = (params) => post("/order/coInfo", params)
export const cancle = (params) => post("/order/cancel", params)//取消订单
export const del = (params) => post("/order/del", params)//删除订单
export const refund = (params) => post("/order/refund", params)//申请退款
export const affirm = (params) => post("/order/affirm", params)//去支付
export const sign = (params) => post("order/sign", params)//取消订单
export const addCustomBuyCar = (params) => post("buycar/addCustomBuyCar", params)//裸石定制添加购物车
export const prolist = (params) => get('/series/getSeriesProlist',params)//系列详情
export const bindwx = (params) => get('/t/bindwx',params)








