/*
 * @Author: your name
 * @Date: 2020-06-11 12:54:13
 * @LastEditTime: 2020-06-17 17:23:41
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\filter\filter.js
 */ 
import Vue from "vue";
import { dateFormat } from "../common/util";
Vue.filter("toRMB", value => {
  return "￥" + parseFloat( (value / 100).toFixed(2));
});
Vue.filter("dateFormat", value => {
  return dateFormat(value, "yyyy-MM-dd");
});
Vue.filter("dateTimeFormat", value => {
  return dateFormat(value, "yyyy-MM-dd hh:mm:ss");
});

Vue.filter("dateTimeFormats", value => {
  return dateFormat(value, "yyyy-MM-dd");
});
