<!--
 * @Author: your name
 * @Date: 2020-06-01 15:44:26
 * @LastEditTime: 2020-12-14 17:17:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\views\production\prod_s.vue
-->
<template>
  <div class="littlelist">
    <div class="left">
      <div class="sub_main">
        <div class="left_top">
          <h2>类别</h2>
          <div class="classify">
            <dl v-for="(item, index) in tablist" :key="index" @click="tagindex(item.name,item.id)">
              <dt>
                <img :src="item.image" />
              </dt>
              <dd>{{ item.name }}</dd>
            </dl>
          </div>
          <p @click="ction()">
            浏览全部产品
            <img src="@/assets/images/浏览全部产品右.png" alt />
          </p>
          <h2 class="select">甄选</h2>
          <dl v-for="(item, index) in select" :key="index" class="select_dl" @click="dz(index)">
            <dt>
              <img :src="item.image" />
            </dt>
            <dd>{{ item.name }}</dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="series">
        <img src="@/assets/images/xs.png" alt class="imgexit" @click="exites()" />

        <div class="series_head">
          <h2>系列</h2>
          <dl>
            <dd @click="tz()">浏览全部系列</dd>

            <dt>
              <img src="@/assets/images/浏览全部产品右.png" alt />
            </dt>
          </dl>
        </div>
        <div class="series_image">
          <div v-for="item in data.series" :key="item.id" @click="goSeries(item.id)">
            <img :src="item.pic.picUrl" alt />
          </div>
        </div>
        <div class="series_carousel">
          <div class="wrapper">
            <swiper :options="swiperOption">
              <swiper-slide v-for="item in data.products" :key="item.id" class="swipers">
                <img :src="item.pic.picUrl" alt class="image" @click="tzlist(item.id)" />
                <p class="swiper_p">{{ item.title }}</p>
              </swiper-slide>
              <!-- 前进后退 -->
            </swiper>
            <div class="swiper-prev forward">
              <img src="@/assets/images/左.png" alt />
            </div>
            <div class="swiper-next backwards">
              <img src="@/assets/images/右.png" alt />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Prod_s",
  components: {},
  props: ["data"],
  data() {
    return {
      tablist: [
        { id: 3, name: "项链", image: require("@/assets/images/项链.png") },
        { id: 2, name: "戒指", image: require("@/assets/images/戒指.png") },
        { id: 4, name: "耳饰", image: require("@/assets/images/耳饰.png") },
        { id: 1, name: "手链", image: require("@/assets/images/手镯.png") },
        // {id:5, name: "耳饰", image: require("@/assets/images/耳饰.png") },
      ],
      select: [
        {
          name: "纪念裸钻定制",
          image: require("@/assets/images/高端裸钻定制.png")
        },
        {
          name: "品牌设计师",
          image: require("@/assets/images/热销明星系列.png")
        }
      ],

      swiperOption: {
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        slidesPerView: 5,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          virtual: true
        },
        spaceBetween: "3%",
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev"
        }
        //自动轮播
        // autoplay: {
        //   delay: 2000,
        //   //当用户滑动图片后继续自动轮播
        //   disableOnInteraction: false
        // }
        //开启循环模式
      },

      // image:[
      //     {name:1,image:require('@/assets/images/系列1.png')},
      //     {name:1,image:require('@/assets/images/系列2.png')},
      //     {name:1,image:require('@/assets/images/系列3.png')},
      //     {name:1,image:require('@/assets/images/系列4.png')}
      // ],
      imagedatelist: [],
      products: []
    };
  },
  methods: {
    loading() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
        target: ".sub-main",
        body: true,
        customClass: "mask"
      });
      setTimeout(function() {
        loading.close();
      }, 300);
    },
    exites() {},
    tz() {
      this.loading();
      this.$router.push({
        path: "/serieslist"
      });
    },
    //列表
    ction() {
      this.loading();
      window.location.href = "/production.html?sify=" + 0;
    },
    //筛选
    tagindex(item, index) {
      this.loading();
      // console.log(item, index);
      window.location.href = "/production.html?sify=" + index;
    },
    dz(e) {
      if (e == 0) {
        this.loading();
        this.$router.push({ path: "/diamondorder" });
      }
      if (e == 1) {
        this.loading();
        this.$router.push({ path: "/designer.html" });
      }
    },
    tzlist(ids) {
      this.loading();
      window.location.href = '/productInfo.html?id='+ ids
      // this.$router.push({
      //   path: "/productInfo.html",
      //   query: {
      //     id: ids
      //   }
      // });
    },
    goSeries(id) {
      this.$router.push({
              path: "/productList.html",
              query: {
                seriesid: id
              }
          });
    }
  },
  mounted() {}
};
</script>
<style lang="less" scoped>
.littlelist {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}
.left {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.5vw;
  .sub_main {
    width: 100%;
    height: 362px;
    display: flex;
    justify-content: center;
    border-right: 1px solid #ccc;
  }
  .left_top {
    width: 73%;
    height: 240px;
    margin: 0 auto;
    h2 {
      font-weight: 500;
      line-height: 30px;
    }
    .classify {
      width: 100%;
      height: 58%;
      margin-top: 18px;
      dl {
        width: 34%;
        height: 22px;
        float: left;
        display: flex;
        justify-content: space-around;
        margin-top: 16px;
        &:hover {
          cursor: pointer;
          color: red;
        }
        dt {
          width: 20%;
          height: 100%;
          img {
            width: 1vw;
            height: 1vw;
          }
        }
        dd {
          width: 70%;
          height: 100%;
        }
      }
    }
    p {
      width: 100%;
      border-bottom: 1px solid #ccc;
      font-weight: 500;
      font-size: 14px;
      height: 2.5vw;
      &:hover {
        cursor: pointer;
      }
      img {
        width: 0.6vw;
        height: 0.65vw;
        margin-left: 10px;
      }
    }
  }
  dl {
    width: 88%;
    height: 25px;
    display: flex;
    justify-content: space-around;
    &:hover {
      cursor: pointer;
      color: red;
    }
    dt {
      width: 15%;
      height: 100%;
      img {
        width: 1vw;
        height: 1vw;
      }
    }
    dd {
      width: 80%;
      height: 100%;
    }
  }
}
.select {
  margin: 20px 0;
}

.select_dl {
  margin: 12px 0;
}
.right {
  width: 62%;
  height: 100%;
}
.series {
  width: 80%;
  height: 85%;
  margin: 1.5vw auto;
  .series_head {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    h2 {
      font-weight: 500;
    }
    dl {
      width: 26%;
      display: flex;
      justify-content: space-around;
      dd {
        line-height: 38px;
        font-size: 14px;
      }
      dt {
        width: 20%;
        line-height: 38px;
      }
      &:hover {
        cursor: pointer;
        color: red;
      }
    }
  }
}
.series_image {
  width: 100%;
  display: flex;
  margin-top: 1vw;
  justify-content: space-around;
  img {
    width: 100%;
    height: 162px;
    border-radius: 3px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }
  div {
    overflow: hidden;
    width: 24%;
  }
}
.series_carousel {
  width: 94%;
  position: relative;
  margin: 3vw auto;
  img {
    width: 80px;
    height: 80px;
  }
  .forward {
    width: 15px;
    height: 30px;
    position: absolute;
    left: -5%;
    top: 40%;
    z-index: 99;
    transform: translateY(-50%);
    outline: none;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .backwards {
    width: 15px;
    height: 30px;
    position: absolute;
    right: -5%;
    top: 40%;
    z-index: 99;
    outline: none;
    transform: translateY(-50%);
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.series_carousel .wrapper {
  .image {
    width: 90%;
    object-fit: cover;
    height: 80px;
    margin-left: 10px;
    &:hover{
       box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }
}
.swiper_p {
  width: 80px;
  font-size: 0.4vw;
  text-align: center;
  line-height: 25px;
  margin-top: 0.6vw;
  height: 25px;
  margin-left: 10px;
}
// .swiper-slide {
//   width: 700px !important;
//   height: 160px;
// }
.swipers {
  width: 17.2% !important;
  &:hover {
    cursor: pointer;
  }
}
.imgexit {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
