/*
 * @Author: your name
 * @Date: 2020-06-03 08:58:30
 * @LastEditTime: 2020-09-08 10:42:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\main.js
 */
import Vue from "vue";
import 'core-js'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./filter/filter";
import Vuex from "vuex";

import VuePhotoZoomPro from 'vue-photo-zoom-pro'

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./style/index.less";
import vueSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
import "./plugins/flexible.js";
import "./assets/css/global.css";
import "./assets/css/animate.css";

Vue.use(VuePhotoZoomPro)
Vue.use(Vuex);
Vue.use(vueSwiper);
Vue.use(ElementUI);
Vue.config.productionTip = false;


Vue.prototype.$setCookie = function (name, value) {
    if (value) {
    var days = 1; //定义一天
    var exp = new Date();
    exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
    // 写入Cookie, toGMTString将时间转换成字符串
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString;
    }
  };
  
  Vue.prototype.$getCookie = function (name) {
    var arr,reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)"); //匹配字段
    if (arr = document.cookie.match(reg)) {
    return unescape(arr[2]);
    } else {
    return null;
    }
  };




new Vue({
  router,
  store,
  render: (h) => h(App),
  async mounted() {
    //初始化获取 token
    console.log("执行了本地")
    this.$store.commit("user/LOADTOKEN");
    //初始化获取 uid
    this.$store.commit("user/LOADUID");
    //初始化获取 用户信息
    this.$store.commit("user/LOADUSERDATA");
    //初始化获取 购物车数量
    this.$store.commit("user/LOADCAR");
    //初始化登录状态
    // await this.$store.dispatch("user/getInfo");
    
  },
}).$mount("#app");
