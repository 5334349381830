/*
 * @Author: your name
 * @Date: 2020-06-09 18:40:17
 * @LastEditTime: 2020-06-09 18:40:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\modules\db.js
 */ 
export default {
    namespaced:true,
    actions:{
        setLocal: function(key, value) {
            window.localStorage.setItem(key, JSON.stringify(value));
          },
          getLocal: function(key) {
            let value = window.localStorage.getItem(key);
            return JSON.parse(value);
          },
          setSession: function(key, value) {
            window.sessionStorage.setItem(key, JSON.stringify(value));
          },
          getSession: function(key) {
            let value = window.localStorage.getItem(key);
            return JSON.parse(value);
          },
          clearOneLocal: function(key) {
            window.localStorage.removeItem(key);
          },
          clearOneSession: function(key) {
            window.sessionStorage.removeItem(key);
          },
          clearAllLocal: function() {
            window.localStorage.clear();
          },
          clearAllSession: function() {
            window.sessionStorage.clear();
          }
    }
}
