<!--
 * @Author: your name
 * @Date: 2020-06-08 09:01:23
 * @LastEditTime: 2020-07-23 15:52:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\layout\components\header_s.vue
--> 
<!--
 * @Author: your name
 * @Date: 2020-06-03 08:58:30
 * @LastEditTime: 2020-06-05 14:12:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\layout\mainHeader.vue
--> 
<template>
  <div class="login_sa">
    <div class="title_s" v-if="type==1">
      <img src="@/assets/images/header/icon_4.png" /> |
      <img src="@/assets/images/header/icon_3.png" />|
      <img src="@/assets/images/header/icon_2.png" />|
      <img src="@/assets/images/header/icon_1.png" />
    </div>
    <div class="title_s" v-if="type==2">
      <div class="imagese" @mouseenter="stopMove()" @mouseleave="Move()">
        <img src="@/assets/images/header/icon_my.png" @click="my()" />
        <logins class="loginse" :type="1"></logins>
      </div>|
      <div class="spw" @click="gw()">
        <img src="@/assets/images/header/icon_gw.png" />
        <el-badge :value="getcar" class="item" v-if="getcar"></el-badge>
      </div>|
      <img src="@/assets/images/header/icon_address.png" @click="address()" />|
      <img src="@/assets/images/header/icon_tel.png" @click="tel()" />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";
export default {
  name: "login",
  props: ["type"],
  computed: {
    ...mapGetters("user", {
      getcar: "getcar",
      getuserdata: "getuserdata"
    })
  },
  data() {
    return {};
  },
  methods: {
    //停止页面滚动
    stopMove() {
      var top = $("#sapp").scrollTop();
      $("#sapp").on("scroll.unable", function() {
        $("#sapp").scrollTop(top);
      });
    },
    //开启页面滚动
    Move() {
      $("#sapp").unbind("scroll.unable");
    },
    //个人
    my() {
      if (this.$store.getters["user/getToken"]) {
        this.$router.push({ path: "/mycenter" });
      } else {
        this.$router.push({ path: "/createLogin.html" });
      }
    },
    //购物车
    gw() {
      if (this.$store.getters["user/getToken"]) {
        this.$router.push({ path: "/shopping" });
      } else {
        this.$router.push({ path: "/createLogin.html" });
      }
    }
  },
  components: {
    logins: () => import("./login_s")
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.login_sa {
  display: flex;
  align-items: center;
  .title_s {
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    color: #000;
    position: absolute;
    right: 10px;
    z-index: 2;
    img {
      width: 25px; /* no */
      height: 25px; /* no */
      margin: 0 10px; /* no */
    }
   
  }
}
.loginse {
  display: none;
  position: absolute;
  right: -10px;
  top: 0;
  z-index: 99;
}
.imagese:hover {
  .loginse {
    display: block;
    background: #ffffff;
    animation: left 1s;
  }
}
.spw {
  position: relative;
  margin: 0;
}
.login_sa .item /deep/ .el-badge__content {
  background: #921d22;
  width: 10px; /* no */
  height: 20px; /* no */
  line-height: 20px; /* no */
  border-radius: 50px; /* no */
}
.item {
  position: absolute;
  right: 2px;/* no */
  top: -9px; /* no */
}
@keyframes left {
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translate(0);
  }
}
</style>
