/*
 * @Author: your name
 * @Date: 2020-06-01 10:03:34
 * @LastEditTime: 2020-06-16 16:36:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\store\index.js
 */

import Vue from "vue";
import Vuex from "vuex";
import order from "./modules/order";
import storage from "./modules/db";
import user from "./modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    order,
    storage,
    user,
  },

  state: {
    carlength: 0,
    height: 0,
    show:false,
    aimg:''
  },
  getters:{
    getimg(state){
      return state.aimg
    }
  },
  mutations: {
    img(state,img){
      state.aimg = img
    },
    show(state,boller){
      state.show = boller
    },
    carnum(state, nums) {
      state.carlength = nums;
    },
    height(state, height) {
      state.height = height;
    },
  },
  actions: {},
});
