<!--
 * @Author: your name
 * @Date: 2020-06-03 08:58:30
 * @LastEditTime: 2020-07-28 17:44:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\layout\mainHeader.vue
--> 
<template>
  <div class="main-header">
    <div class="right">
      <div class="icon collect" @click="collect()"></div>

      <div class="cars" @click="shopping()">
        <el-badge :value="getcar" class="item" v-if="getcar"></el-badge>
        <div class="icon shop-car"></div>
      </div>

      <div class="mainuesr">
        <div class="icon user" @mouseenter="stopMove()" @mouseleave="Move()">
          <span class="spans">{{getuserdata?getuserdata.nick:false || "登录"}}</span>
          <login :class="islogin?'login':'none'"></login>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";
export default {
  name: "mainFooter",
  data() {
    return {
      name: "登录",
      num: false,
      islogin: false,
      user:0
    };
  },
  components: {
    login: () => import("./components/login_s")
  },
  computed: {
    ...mapGetters("user", {
      getcar: "getcar",
      getuserdata: "getuserdata"
    })
  },
  methods: {
    loading() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
        target: ".sub-main",
        body: true,
        customClass: "mask"
      });
      setTimeout(function() {
        loading.close();
      }, 300);
    },
    //停止页面滚动
    stopMove() {
      // console.log(1);
      this.islogin = true;
      var top = $("#sapp").scrollTop();
      $("#sapp").on("scroll.unable", function() {
        $("#sapp").scrollTop(top);
      });
    },
    //开启页面滚动
    Move() {
      this.islogin = false;
      $("#sapp").unbind("scroll.unable");
    },
    //收藏
    collect() {
      if (this.$store.getters["user/getToken"]) {
        this.loading();
        this.$router.push({ path: "/collect" });
      } else {
        this.loading();
        this.$router.push({ path: "/createLogin.html" });
      }
    },
    //购物车
    shopping() {
      if (this.$store.getters["user/getToken"]) {
        this.loading();
        this.$router.push({ path: "/shopping" });
      } else {
        this.loading();
        this.$router.push({ path: "/createLogin.html" });
      }
    }
  },
  watch:{
 
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.main-header {
  width: 100%;
  height: 50px; /* no */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 50px; /* no */
  position: relative;
  z-index: 100;
  float: right;
  .icon {
    position: relative;
    z-index: 80;
    height: 20px; /* no */
  }
  .collect {
    width: 25px; /* no */
    height: 25px; /* no */
    background-image: url("../assets/images/headers/loveicon.png");
    background-size: 100% 100%;
    position: absolute;
    right: 170px; /* no */
    top: 15px; /* no */
    &:hover {
      cursor: pointer;
    }
  }
  .shop-car {
    width: 25px; /* no */
    height: 25px; /* no */
    background-image: url("../assets/images/headers/caricon.png");
    background-size: 100% 100%;
    position: relative;
    right: 5px; /* no */
  }
  .user {
    width: 25px; /* no */
    height: 25px; /* no */
    position: relative;
    right: 5px; /* no */
    line-height: 20px; /* no */
    font-size: 16px; /* no */
    background-image: url("../assets/images/headers/usericon.png");
    background-size: 100% 100%; /* no */
    &:hover {
      cursor: pointer;
      color: #ffffff;
    }
    &:hover .login {
      animation: left 300ms;
    }
  }
}
.login {
  position: absolute;
  right: -80px; /* no */
  top: -20px;
  background: #ffffff;
  z-index: 100;
  color: #333333;
}
@keyframes left {
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translate(0);
  }
}
.main-header .item /deep/ .el-badge__content {
  background: #921d22;
  width: 10px; /* no */
  height: 20px; /* no */
  line-height: 20px; /* no */
  border-radius: 50px; /* no */
}
.item {
  position: absolute;
  right: -2px; /* no */
  top: -9px; /* no */
  z-index: 100;
}
.car {
  width: 20px;
  height: 20px;
}
.cars {
  height: 25px; /* no */
  width: 25px; /* no */
  position: absolute;
  right: 122px; /* no */
  top: 14px; /* no */
  &:hover {
    cursor: pointer;
  }
}
.mainuesr {
  position: relative;
  right: 0;
  z-index: 999;
  width: 50px; /* no */
  span {
    display: inline-block;
    width: 50px;
    overflow: hidden;
    white-space: nowrap;
    height: 30px; /* no */
    margin-left: 30px; /* no */
  }
}
.border {
  width: 100%;
  height: 1;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.spans {
  line-height: 26px; /* no */
  font-size: 14px; /* no */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.none {
  display: none;
}
</style>
