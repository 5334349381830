<template>
  <div class="footer" id="footer">


    <div class="footer-link">

      <div class="logo">
        <div class="imgages"><img src="../assets/logo.png" /></div>
         <div class="phone-image">
          <div></div>
        </div>
      </div>
       
      <div class="link-item-parent">
        <div class="link-item" v-for="(item, index) in linkList" :key="index">
    
          <div class="title">{{ item.title }}</div>
    
          <div class="list">
            <span
              v-for="(subItem, subIndex) in item.list"
              :key="subIndex"
              @click="goPage(subItem.link,subItem.id)"
               @mousemove="tsk(subItem.id)" @mouseout="csk()"
            >{{ subItem.label }}</span>
          </div>

        </div>
        
      </div>
     
      <!-- <div  class="link-items" v-for="(item, index) in concatList" :key="index">
    
          <div class="title">{{ item.title }}</div>
    
          <div class="list">
            <span
             :class="[item.id, 'concact-item']"
              v-for="(subItem, subIndex) in item.list"
              :key="subIndex"
               @mousemove="tel(subItem.id)" @mouseout="csk()"
            >{{ subItem.label }}</span>
          </div>

        </div> -->


       <div class="codss" v-if="tels">
            <img src="../assets/images/gzh.jpg" alt />
            <div>扫一扫，关注公众号</div>
        </div>
      <div :class="calls?'xs pos':'yc pos'">
      
        <p v-if="!email" class="tels">010-56984858</p>
        <p v-if="email" class="tels">server@irisgem.cn</p>
      </div>

    </div>
    <div class="copyright">
      COPYRIGHT © 塞维利亚（北京）文化科技有限公司. BY DEMUED.
     
    </div>
     <div class="copyrights">
     <a href="https://beian.miit.gov.cn" target="_blank"> <i class="icon-police"><img src="../assets/images/icon-police.png" alt=""></i>京ICP备19058244号</a>
     <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802032222" target="_blank"> <i class="icon-police" style="padding-left:20px"><img src="../assets/images/icon-police.png" alt=""></i>京公网安备 11010802032222号</a>
      <i class="icon-police"><img src="../assets/images/icon-police.png" alt=""></i>增值电信业务经营许可证： 京B2-20201935
    </div>


  </div>
</template>

<script>
export default {
  name: "mainFooter",
  data() {
    return {
      linkList: [
        {
          title: "顾客服务",
          list: [
            { id:'0',label: "常见问题", link: "/problem.html" },
            { id:'1',label: "隐私政策", link: "/privacypolicy" },
            { id:'2',label: "条款细则", link: "/orderargument" },
          ]
        },
        {
          title: "企业信息",
          list: [
            { id:'3',label: "品牌介绍", link: "/irisgeminfo.html" },
            { id:'4',label: "关于我们", link: "/introduce.html" },
            { id:'5',label: "客户故事", link: "/family.html" },
          ]
        },
         {
          title: "关注我们",
          list: [
            { id:'weibo',label: "官方微博",  link:''},
             {id:'watch', label: "官方微信",  link:''},
            ]
        },
        {
          title: "联系我们",
          list: [
             { id:'tel',label: "客服电话",  link:''},
             {id:'email', label: "企业邮箱",  link:''},
            ]
        },
      ],
      concatList: [
         
       
      ],
      show: false,
      calls:false,
      tels:true,
      email:false
    };
  },

  methods: {
    tsk(id){
      // console.log(id)
      // if(id=='watch'){
      //   this.calls = true
      //   this.tels = true
      // }
       
       if(id == 'tel'){
        // document.getElementById('sendMail').click()
         this.calls = true
        }else if(id == 'email'){
          this.calls = true
          this.email = true
        }

    },
    // tel(id){
    //   console.log(id)
    //      if(id == 'tel'){
    //     // document.getElementById('sendMail').click()
    //      this.calls = true
    //      this.tels = false
    //     }else if(id == 'email'){
    //       this.calls = true
    //       this.email = true
    //     }
    // },
    csk(){
      this.show= false,
      this.calls=false,
      this.email=false
    },
    // ts(item){
    //   if(id == 'weibo'){
    //     window.open('https://weibo.com/u/7347744154?is_all=1')
    //   }else if(id == 'qq'){
    //     window.open('tencent://message/?uin=2862855195&Site=http://vps.shuidazhe.com&Menu=yes')
    //   }
    // },
    goPage(url,id) {

       if(id == 'weibo'){
        window.open('https://weibo.com/u/7347744154?is_all=1')
      }
      if (!url) {
        return;
      }
      console.log(url);
      this.$router.push({ path: url });
    }
  }
};
//  .concact-item::before {
//     content: "";
//     width: 20px;/* no */
//     height: 20px;/* no */
//   }
//   .weibo::before {
//     background-image: url("../assets/images/weibo.png");
//     background-size: 100% 100%;
//   }
//   .qq::before {
//     background-image: url("../assets/images/center/icon_wx.png");
//     background-size: 100% 100%;
//   }
//   .email::before {
//     background-image: url("../assets/images/email.png");
//     background-size: 100% 100%;
//   }
//   .tel::before{
//     background-image: url("../assets/images/center/icon_tel.png");
//     background-size: 100% 100%;
//   }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.pos{
  position: absolute;
  right: -300px;
  top: 50%;
  transform: translateY(-50%)
}
a{
  color: #999999;
}
.footer {
  width: 100%;
  background: #f5f5f5;
  height: 420px;/* no */
  position: relative;
}
.icon-police{
  width: 20px;
  height: 20px;
  padding: 0 5px;
  img{
    object-fit: contain;
}
}
.footer-link{
  width: 960px;/* no */
  display: flex;
  justify-content: space-around;
  position: relative;
  top: 35%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.copyrights{
  width: 100%;
  font-size: 14px;/* no */
  color: #999999;
  text-align: center; 
  position: absolute;
  bottom: 0px;/* no */
  height: 60px;/* no */
  line-height: 60px;/* no */
  border-top: 1px solid #e7e7e7; 
}
.copyright{
  width: 100%;
  font-size: 14px;/* no */
  color: #999999;
  text-align: center; 
  position: absolute;
 bottom: 60px;/* no */
  height: 60px;/* no */
  line-height: 60px;/* no */
  border-top: 1px solid #e7e7e7; 
}
.logo{
  height: 180px;/* no */
  width: 200px;/* no */
  margin-top: -20px;
  .imgages{
    width: 100%;
    text-align: center;
     img{
      width: 100%;/* no */
      object-fit: contain;
    }
  }
  .phone-image{
    font-size: 16px;/* no */
    color: #921d22;
    margin-top: 50px;/* no */
    div{
      padding-left: 38px;/* no */
      line-height: 30px;/* no */
    }
  }
  // .phone-image::before{
  //   content: "";
  //   position: absolute;
  //   width: 30px;/* no */
  //   height:30px;/* no */
  //   ;
  //   background-size: 100% 100%;
  // }
}
.link-item-parent{
  width: 500px;/* no */
  display: flex;
  justify-content: space-between;
}
.link-item{
  margin: 0 20px;/* no */
  .title {
    font-size: 16px;/* no */
    color: #000;
    margin-bottom: 20px;/* no */
  }
  .list {
    display: flex;
    flex-direction: column;
    color: #aaa;
    font-size: 14px;/* no */
    line-height: 30px;/* no */
    > span {
      cursor: pointer;
    }
    span:hover{
    color: #921d22;
    cursor: pointer;
    }
  }
}
.link-items{
  margin: 0 20px;/* no */
  .title {
    font-size: 16px;/* no */
    color: #000;
    margin-bottom: 20px;/* no */
  }
  .list {
    display: flex;
    flex-direction: column;
    color: #aaa;
    font-size: 14px;/* no */
    line-height: 30px;/* no */
    > span {
      cursor: pointer;
    }
    span:hover{
    color: #921d22;
    cursor: pointer;
    }
  }
}

.contact{
  width: 190px;/* no */
  height: 150px;/* no */
  .concact-item {
    color: #999;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 12px;/* no */
    &:first-child{
      margin-bottom: 24px;/* no */
      margin-top: 10px;/* no */
    }
   &:first-child span{
     font-size: 16px;/* no */
     color: #000;
  }
  &:hover{
    color: #921d22;
    cursor: pointer;
  }
  &:last-child{
    margin-top:9px;/* no */
  }
   > span {
    font-size: 14px;/* no */
  }
}


 
}
.codss{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -20px;
  img{
    margin: auto;
    width: 120px;
    height: 120px
  }
  div{
    width: 100%;
    text-align: center;
    line-height: 60px;
  }
}

.code{
  width: 150px;/* no */
  height: 150px;/* no */
  position: absolute;
  right: -150px;
  text-align: center;
  img{
    width: 100px;/* no */
    height: 100px;/* no */
  }
  div{
    font-size: 14px;/* no */
    color: #666;
    line-height: 30px;/* no */
  }
}
.xs{
  display: block;
  animation: identifier 0.5s;
}
.yc{
  display: none;
}



@keyframes identifier {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}


.tels{
  width: 280px;
  line-height: 140px;
  font-size: 24px;
  text-align: left;
}











</style>
